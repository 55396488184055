<template>
  <div class="vg_wrapper">
    <el-card>
      <publicTips></publicTips>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getUsersNow()">
          <el-row :gutter="24">
            <el-col :md="8">
              <el-form-item label="员工账号：">
                <el-input size="small" v-model.trim="searchForm.user_name" clearable placeholder="请填写账号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="员工姓名：">
                <el-input size="small" v-model.trim="searchForm.stff_name" clearable placeholder="请填写姓名"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :md="8">
              <el-form-item label="部门名称：">
                <el-select v-model="searchForm.dept_id" placeholder="请选择部门" clearable @visible-change="getDeptV1($event)" size="small">
                  <el-option v-for="item in deptGroupOpt" :key="item.dept_id" :label="item.dept_name" :value="item.dept_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="小组名称：">
                <el-select v-model="searchForm.dept_team_id" placeholder="请选择小组" clearable @visible-change="getDeptTeams" size="small">
                  <el-option
                    v-for="item in deptTeamsGroupOpt"
                    :key="item.dept_team_id"
                    :label="item.dept_team_name"
                    :value="item.dept_team_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :md="14">
              <el-form-item label="时间范围：">
                <div class="block">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getUsersNow()" class="vg_ml_16">查询</el-button>
              </el-form-item>
            </el-col>
            <!--         <el-col :md="4">-->
            <!--           <el-form-item>-->
            <!--             <el-button size="small" type="primary" icon="el-icon-search" @click="getUsersNow()">查询</el-button>-->
            <!--           </el-form-item>-->
            <!--         </el-col>-->
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <!-- <el-button
          type="primary"
          size="small"
          @click="addRow()"
          :disabled="!btn.add"
          ><i class="el-icon-plus"></i> 新增</el-button
        > -->
        <el-button type="danger" size="small" @click="delRow()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 注销</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            v-loading="loadFlag"
            ref="multiTable"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @row-dblclick="dbClickJp"
            border
            class="vg_cursor"
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="账号" prop="user_name" :show-overflow-tooltip="true" />
            <el-table-column label="姓名" prop="stff_name" :show-overflow-tooltip="true" />
            <el-table-column label="部门" prop="dept_name" :show-overflow-tooltip="true" />
            <el-table-column label="小组" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.dept_team_name">
                  {{ scope.row.dept_team_name }}
                </span>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="create_time" :formatter="formatDate" />
            <el-table-column label="注销时间" prop="delete_time" :formatter="formatDateDel" />
            <el-table-column label="账号状态" prop="user_status" align="center">
              <template slot-scope="scope">
                <el-tag type="info" v-if="scope.row.user_status == 0">已注销</el-tag>
                <el-tag v-if="scope.row.user_status == 1">未使用</el-tag>
                <el-tag type="success" v-if="scope.row.user_status == 2">使用中</el-tag>
                <el-tag type="warning" v-if="scope.row.user_status == 3">卡使用</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination ref="pubPagination" :totalPage="totalPage" @changePageSearch="changePageSearch"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { deptAPI } from '@api/modules/department';
import { userAPI } from '@api/modules/user';
import pubPagination from '@/components/common/pubPagination';
import publicTips from '@/views/component/publicTips';
export default {
  name: 'UserList',
  components: {
    pubPagination,
    publicTips
  },
  data() {
    return {
      searchForm: {
        user_name: '',
        stff_name: '',
        dept_id: null,
        dept_team_id: null,
        timeValue: {
          startTime: null,
          endTime: null
        }
      },
      cpttGroupOpt: [],
      deptGroupOpt: [],
      deptTeamsGroupOpt: [],
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      loadFlag: true
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/user_add' || from.path === '/user_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getUsersList();
    },
    getUsersList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      const searchBody = {
        user_name: this.searchForm.user_name,
        stff_name: this.searchForm.stff_name,
        dept_id: this.searchForm.dept_id,
        dept_team_id: this.searchForm.dept_team_id,
        page_no: this.currentPage,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime
      };
      get(userAPI.getUsers, searchBody)
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取deptId
    getDeptV1(flag) {
      if (flag === true && this.deptGroupOpt.length === 0) {
        get(deptAPI.getAllDeptsV1)
          .then(res => {
            if (res.data.code === 0) {
              this.deptGroupOpt = res.data.data;
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          });
      }
    },
    // 获取deptTeamId
    getDeptTeams(flag) {
      if (flag === true && this.deptTeamsGroupOpt.length === 0) {
        get(deptAPI.getAllDeptTeams)
          .then(res => {
            if (res.data.code === 0) {
              this.deptTeamsGroupOpt = res.data.data;
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          });
      }
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    formatDateDel(row) {
      if (!this.helper.toStringDate(row.delete_time)) {
        return '暂未注销';
      } else {
        return this.helper.toStringDate(row.delete_time);
      }
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 查询
    getUsersNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getUsersList();
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getUsersList();
    },
    // 双击编辑
    dbClickJp(row) {
      this.jump('/user_edit', {
        perm_id: this.$route.query.perm_id,
        form_id: row.user_id
      });
    },
    // 新增
    addRow() {
      this.jump('/user_add', { perm_id: this.$route.query.perm_id });
    },
    delRow() {
      if (this.multiSelection.length > 0) {
        const ids = [];
        this.multiSelection.map(item => {
          ids.push(item.user_id);
        });
        this.$confirm('是否注销该数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            post(userAPI.deleteUserByIds, { user_id_list: ids })
              .then(res => {
                if (res.data.code === 0) {
                  let mg = res.data.msg;
                  let tp = 'success';
                  this.$message({ message: mg, type: tp });
                  if (ids.length === this.tableData.length) {
                    if (this.currentPage > 1) {
                      this.currentPage = this.currentPage - 1;
                      this.$refs.pubPagination.currentPage = this.currentPage;
                    }
                  }
                  this.initData();
                } else {
                  let mg = res.data.msg;
                  let tp = 'error';
                  this.$message({ message: mg, type: tp });
                  this.initData();
                }
              })
              .catch(res => {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消注销'
            });
            this.$refs.multiTable.clearSelection();
          });
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
